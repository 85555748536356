<template>
  <a href="#" class="block hover:bg-gray-200 my-1 px-4 py-1 font-medium text-gray-800">
    <slot/>
  </a>
</template>
  
<script>
  export default {
    name: "AppDropdownItem"
  };
</script>
  